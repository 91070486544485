<template>
  <div class="w_auto">
    <el-row>
      <el-col :span="24" class="xiaoxi mb15">
        <SystemMessage></SystemMessage>
        <!-- <el-tabs v-model="activeName" class="mt15">
          <el-tab-pane label="系统消息" name="first">
            
          </el-tab-pane> -->
          <!-- <el-tab-pane label="互动评论" name="second">
            <el-card class="mt15">
              <div class="spangreen">
                <div
                  class="cursor"
                  :style="{ color: unRead == 1 ? '#f0955d' : '' }"
                  @click="allCound('', 1)"
                >
                  全部({{ readCound.allCount }})
                </div>
                <div
                  class="cursor"
                  :style="{ color: unRead == 0 ? '#f0955d' : '' }"
                  @click="unReadCound(0)"
                >
                  未读({{ readCound.unReadCount }})
                </div>
              </div>
            </el-card>
            <el-card class="mt15">
              <div class="systemessage">
                <div class="interaction" v-if="questionList.length != 0">
                  <ul>
                    <li>
                      <div
                        class="onehf"
                        v-for="(item, index) in questionList"
                        :key="index"
                      >
                        <div class="userimg">
                          <a href="javascript:void();">
                            <img :src="item.headPortrait" alt="" />
                          </a>
                          <div class="userinfo">
                            <p class="name">
                              <span>{{ item.userName }}</span>
                            </p>
                            <p>
                              {{ item.updateTime }}
                              <el-button
                                type="text"
                                class="colorRed"
                                @click="deleteClick(item.askQuestionId)"
                                >删除</el-button
                              >
                            </p>
                            <div></div>
                          </div>
                        </div>
                        <div class="plcontent">
                          <a href="" class="coursetit">{{ item.courseName }}</a
                          >的成员{{ item.userName }}向你互动：{{
                            item.askQuestion
                          }}
                        </div>
                        <div class="replay">
                          <div
                            @click="
                              pinglunClick(
                                item.askQuestionId,
                                item.askReplyNum,
                                (item.pinglun = !item.pinglun)
                              )
                            "
                          >
                            评论({{ item.askReplyNum }})
                          </div>
                          <div
                            @click="
                              huifuClick(
                                item.askQuestionId,
                                item.askReplyNum,
                                (item.huifu = !item.huifu)
                              )
                            "
                          >
                            回复
                          </div>
                        </div>
                        <div class="huifu">
                          <div
                            class="huifu-li"
                            v-if="item.pinglun && item.askReplyNum != 0"
                          >
                            <div
                              v-for="(i, index) in pingLunList"
                              :key="index"
                              style="margin-bottom: 20px"
                            >
                              <div class="userimg">
                                <a href="javascript:void();">
                                  <img :src="i.headPortrait" alt="" />
                                </a>
                                <div class="userinfo">
                                  <p class="name">
                                    <span>{{ i.userName }}</span>
                                  </p>
                                  <p>
                                    {{ i.updateTime }}
                                    <el-button
                                      type="text"
                                      class="colorRed"
                                      @click="
                                        deleteHuiFuClick(
                                          i.askReplyId,
                                          i.askQuestionId,
                                          item.pinglun
                                        )
                                      "
                                      >删除</el-button
                                    >
                                  </p>
                                </div>
                                <div></div>
                              </div>
                              <div class="plcontent">
                                {{ i.askReply }}
                              </div>
                            </div>
                          </div>
                          <div class="mt15" v-if="item.huifu">
                            <div class="comment-form">
                              <div class="avatar-box"></div>
                              <div class="form-box flex-between">
                                <img
                                  :src="item.headPortrait"
                                  alt=""
                                  class="repimg"
                                />
                                <el-input
                                  class="rich-input"
                                  v-model="huifuinput"
                                  placeholder="请输入内容"
                                  @input="replyInput"
                                ></el-input>
                                <el-button
                                  type="primary"
                                  :disabled="disabled"
                                  @click="huiFuInputClick(item.askQuestionId)"
                                  >回复</el-button
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <el-empty
                    :image-size="200"
                    description="暂无未读消息"
                  ></el-empty>
                </div>
              </div>
            </el-card>
          </el-tab-pane> -->
        <!-- </el-tabs> -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SystemMessage from "./systemMessage";
export default {
  inject: ["reload"],
  components: { SystemMessage },
  data() {
    return {
      token: localStorage.getItem("pc_token"),
      activeName: "first",
      disabled: true, //回复禁用
      pinglun: false,
      huifu: false,
      huifuinput: "",
      readCound: {},
      questionList: [],
      pingLunList: [],
      unRead: 1,
    };
  },
  mounted() {
    // this.queryUserTrainCourseAskQuestionList();
    // this.getUserAskReply();
  },
  methods: {
    allCound(val, num) {
      this.unRead = num;
      this.queryUserTrainCourseAskQuestionList(val);
    },
    unReadCound(val) {
      this.unRead = val;
      this.queryUserTrainCourseAskQuestionList(val);
    },
    // 评论
    pinglunClick(id, num, pinglun) {
      if (num != 0 && pinglun == true) {
        this.queryTrainCourseAskReplyList(id);
      }
    },
    huifuClick(id, huifu) {
      if (huifu == true) {
        this.queryTrainCourseAskReplyList(id);
      }
    },
    // 回复输入框
    replyInput() {
      if (this.huifuinput != "") {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    huiFuInputClick(askId) {
      this.saveTrainCourseAskReply(askId);
    },
    // 回复接口
    saveTrainCourseAskReply(askId) {
      this.$api
        .saveTrainCourseAskReply({
          askQuestionId: askId,
          askReply: this.huifuinput,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.$message.success("回复成功");
            this.queryTrainCourseAskReplyList(askId);
            this.queryUserTrainCourseAskQuestionList();
            this.huifuinput = "";
            this.disabled = true;
          }
        });
    },
    // 删除回复信息
    deleteHuiFuClick(id, asId, pinglun) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteTrainCourseAskReply(id, asId);
          this.queryUserTrainCourseAskQuestionList();
          pinglun = false;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    deleteTrainCourseAskReply(id, asId) {
      this.$api
        .deleteTrainCourseAskReply({
          askReplyId: id,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.$message.success("删除成功");
            this.queryTrainCourseAskReplyList(asId);
          }
        });
    },
    // 评论
    queryTrainCourseAskReplyList(id) {
      this.$api
        .queryTrainCourseAskReplyList({
          askQuestionId: id,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.pingLunList = res.data;
          }
        });
    },
    // 查询个人互动消息
    queryUserTrainCourseAskQuestionList(val) {
      this.$api
        .queryUserTrainCourseAskQuestionList({
          isRead: val,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.questionList = res.data.data;
            this.questionList.map((item, index) => {
              this.$set(item, "pinglun", false);
              this.$set(item, "huifu", false);
            });
          }
        });
    },
    // 删除按钮
    deleteClick(id) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteCourseAskQuestion(id);
          this.unRead = 1;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 删除提问信息
    deleteCourseAskQuestion(id) {
      this.$api
        .deleteCourseAskQuestion({
          askQuestionId: id,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.$message.success("删除成功!");
            this.queryUserTrainCourseAskQuestionList();
          }
        });
    },
    // 统计所有的提问回复数量
    getUserAskReply() {
      this.$api
        .getUserAskReply({
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.readCound = res.data;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.spangreen {
  display: flex;
}
.mb15{
  margin-bottom: 15px;
}
.spangreen div {
  padding: 5px 10px;
  color: #333;
  font-size: 14px;
}
.cursor {
  cursor: pointer;
}
.mt15 {
  margin-top: 15px;
}
.interaction {
  // margin-bottom: 70px;
}
.interaction li {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}
.onehf {
  padding: 15px 10px 10px;
  background: #fcfcfc;
}
.userimg {
  display: flex;
}
.userimg img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: url(../../assets/images/onlinecourses/avatar.png) no-repeat;
  background-size: 48px 48px;
  margin-right: 5px;
}
.userinfo .name {
  font-size: 16px;
}
.userinfo p {
  font-size: 14px;
  color: #666;
}
.colorRed {
  // opacity: 0;
  cursor: pointer;
  transition: all 0.3s ease-in;
  color: #c73709;
  margin-left: 10px;
}
// .colorRed:hover{
//   opacity: 1;
//   color: #c73709;
// }
.coursetit {
  margin-right: 5px;
  color: #3ce4bc;
  font-size: 14px;
}
.plcontent {
  font-size: 14px;
}
.replay {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 15px;
  text-align: right;
}
.replay div {
  margin: 0 10px;
  cursor: pointer;
  color: #666;
}
.huifu {
  padding: 0 0 0 30px;
  .huifu-li {
    margin-top: 15px;
    padding: 10px;
    background: #fcfcfc;
    .userimg {
      display: flex;
    }
  }
}
.mt15 {
  margin-top: 15px;
}
.comment-form {
  max-width: 100%;
  padding: 0.2rem 0.0964rem;
  margin-bottom: 15px;
  display: flex;
  background-color: #fafbfc;
  border-radius: 3px;
  .avatar-box {
    flex: 0 0 auto;
  }
  .form-box {
    flex: 1 1 auto;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
  .repimg {
    width: 45px;
    height: 45px;
    margin-right: 5px;
    border-radius: 50%;
    background: url(../../assets/images/onlinecourses/avatar.png) no-repeat;
    background-size: 45px 45px;
  }
  .form-box .rich-input {
    width: 100%;
    margin-right: 10px;
    border-radius: 3px;
    border: 1px solid #f1f1f1;
    background-color: #fff;
    overflow: hidden;
    flex: 1;
  }
}
.xiaoxi::v-deep .el-tabs__active-bar {
  height: 0px;
}
.xiaoxi::v-deep .el-tabs__nav-wrap::after {
  height: 0px;
}
</style>