<template>
  <div>
    <el-card class="mt15" >
    <div class="spangreen">
        <div class="cursor" :style="{ color: (msgConut == 1 ?'#f0955d' : '')}" @click="allCound('',1)">全部({{noticeNum.allCount}})</div>
        <div class="cursor" :style="{ color: (msgConut == 0 ?'#f0955d' : '')}" @click="unReadCound(0)">未读({{noticeNum.unReadCount}})</div>
    </div>
    </el-card>
    <el-card class="mt15">
        <div class="systemessage">
            <div class="xitong" v-if="noticeList.length!=0">
                <div class="onehf" v-for="(i,index) in noticeList" :key="index" @click="goDetail(i)">
                     <el-card class="">
                        <div class="content">
                            <span>{{i.noticeTheme}}</span>
                            <span>{{i.createTime}}</span>
                        </div>
                        <div>{{i.whrName}}</div>
                     </el-card>
                </div>
                
            </div>
            <div v-else>
                <el-empty :image-size="200" description="暂无未读消息"></el-empty>
            </div>
             <div class="page mt15 mb20">
                <el-pagination
                background
                layout="prev, pager, next"
                :page-size="pageSize"
                :total="count"
                :current-page="page"
                @current-change="handleCurrentChange"
                >
                </el-pagination>
            </div>
        </div>
    </el-card>
  </div>
</template>

<script>
export default {
    data(){
        return{
            token: localStorage.getItem("pc_token"),
            noticeNum:{},
            noticeList:[],
            msgConut:1,
            count:0,
            pageSize:10,
            page: 1
        }
    },
    mounted(){
        this.getChannelNoticeNum()
        this.queryMemberUserNoticeList()
    },
    methods:{
        goDetail(i){
            if(i.isRead==0){
                this.updateNoticeRead(i.noticeId)
            }
            this.$router.push({ 
                path:'/interactive/detail',
                query: {id:i.noticeId}
            })
            
        },
        allCound(val,num){
            this.msgConut = num
            this.queryMemberUserNoticeList(val)
        },
        unReadCound(val){
            this.msgConut = val
            this.queryMemberUserNoticeList(val)
        },
        // 查询通知数量
        getChannelNoticeNum(){
            this.$api.getChannelNoticeNum({
                token:this.token
            }).then(res => {
                if(res.code == 10000){
                    this.noticeNum = res.data
                }
            })
        },
        // 更新已读
        updateNoticeRead(noticeId){
            this.$api.updateNoticeRead({
                token:this.token,
                noticeId:noticeId
            }).then(res => {
               
            })
        },
        queryMemberUserNoticeList(val){
            this.$api.queryMemberUserNoticeList({
                page:this.page,
                limit: this.pageSize,
                isRead:val,
                token:this.token
            }).then(res => {
                if(res.code == 10000){
                    this.noticeList = res.data.data
                    this.count = res.data.count
                }
            })
        },
         // 分页
        handleCurrentChange(curPage){
            this.page = curPage
            this.queryMemberUserNoticeList()
        },
        
    }
}
</script>

<style lang="scss" scoped>
.spangreen{
  display: flex;
}
.page{
    text-align: right;
}
.spangreen div {
    padding: 5px 10px;
    color: #333;
    font-size: 14px;
}
.cursor {
    cursor: pointer;
}
.mb15{
  margin-bottom: 15px;
}
.xitong{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    .onehf{
        padding: 0px 20px 10px;
        background: #fcfcfc;
        font-size: 12px;
        color: #666;
        cursor: pointer;
        .content{
            display: flex;
            justify-content: space-between;
            // padding-bottom: 10px;
        }
    }
}
.mt15 {
    margin-top: 15px;
}

</style>